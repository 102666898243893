body {  
  height: 100vh;
  overflow-y: hidden;
}

.container{
  overflow-x: hidden;
}
.card {
  background: #fff;
  transition: 0.5s;
  border: 0;
  /* margin-bottom: 30px; */
  border-radius: 0.55rem;
  position: relative;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
}

.chat-app {
  /* border: rgba(0, 0, 0, 0.3) 1px solid; */
}

.chat-app .people-list {
  width: 280px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 15px 20px;
  z-index: 7;
}

@media screen and (max-width: 768px) {
  .chat-app .people-list {
    padding: 10px;
  }
}

.chat-app .chat {
  margin-left: 280px;
  border-left: 1px solid #eaeaea;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100vh; */
  overflow-x: auto;
}

.people-list {
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  flex-grow: 1;
  overflow-y: auto;
}

.people-list .chat-list {
  max-height: calc(100vh - 110px);
  overflow-y: auto;
}

.people-list .chat-list li {
  padding: 10px 15px;
  list-style: none;
  border-radius: 3px;
}

.people-list .chat-list li:hover,
.people-list .chat-list li.active {
  background: #efefef;
  cursor: pointer;
}

.people-list .chat-list li.active {
  background: #efefef;
}

.people-list .chat-list li .name {
  font-size: 15px;
}

.people-list .chat-list svg {
  color: #049853;
}

.people-list .chat-list img {
  width: 45px;
  border-radius: 50%;
}

.people-list img {
  float: left;
  border-radius: 50%;
}

.people-list .about {
  float: left;
  padding-left: 8px;
}

.people-list .status {
  color: #999;
  font-size: 13px;
}

.people-list.open {
  left: 0 !important;
}

.chat .chat-header {
  padding: 15px 20px;
  border-bottom: 2px solid #f4f7f6;
}

.chat .chat-header img {
  float: left;
  width: 60px;
}

.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px;
}

@media screen and (max-width: 768px) {
  .chat .chat-history {
    padding: 20px;
    border-bottom: 2px solid #fff;
    flex-grow: 1;
    overflow-y: auto;
    height: calc(93vh - 200px);
  }
}

.chat .chat-history ul {
  padding: 0;
}

.chat .chat-history ul li {
  list-style: none;
  margin-bottom: 30px;
}

.chat .chat-history ul li:last-child {
  margin-bottom: 0px;
}

.chat .chat-history .message-data {
  margin-bottom: 15px;
}

.chat .chat-history .message-data img {
  border-radius: 40px;
  width: 40px;
}

.chat .chat-history .message-data-time {
  color: #434651;
  padding-left: 6px;
}

.chat .chat-history .message {
  color: #444;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  display: inline-block;
  position: relative;
}

.chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

.chat .chat-history .my-message {
  background: #efefef;
}

.chat .chat-history .my-message:after {
  bottom: 100%;
  left: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #efefef;
  border-width: 10px;
  margin-left: -10px;
}

.chat .chat-history .other-message {
  background: #e8f1f3;
  text-align: left;
  overflow-wrap: anywhere;
}

.chat .chat-history .other-message:after {
  border-bottom-color: #e8f1f3;
  bottom: 100%;
  left: 93%;
  border-width: 10px;
  margin-left: -10px;
}

.chat .chat-message {
  padding: 20px;
  padding-top: 0px;
  border-top: 2px solid #fff;
  /* position: sticky;
    bottom: 70px; */
  background: white;
}

@media screen and (max-width: 768px) {
  .chat .chat-message {
    padding: 10px !important;
  }
}

.chat-app .chat-list li a {
  color: #000;
}

.chat-app .chat-list li a:hover {
  color: #000;
  text-decoration: none;
}

.chat-app .close-btn {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #333;
  z-index: 11;
}

.chat-app .close-btn:focus {
  outline: none;
}

.header-title {
  text-align: center;
  width: 100%;
  display: block;
}

@media only screen and (max-width: 767px) {
  .header-title {
    font-size: 12px;
    text-align: left;
  }

  .chat-app .people-list.open .close-btn {
    display: block;
  }

  .chat-app .people-list {
    height: 100%;
    width: 100%;
    position: fixed;
    left: -100%;
    top: 0;
    overflow-y: auto;
    background: #fff;
    z-index: 10;
    transition: left 0.3s;
  }

  .chat-app .people-list.open {
    left: 0;
  }

  .chat-app .chat {
    margin: 0;
  }

  .chat-app .chat .chat-header {
    border-radius: 0.55rem 0.55rem 0 0;
  }

  .chat-app .chat-history {
    /* max-height: 100vh; */
    overflow-x: auto;
  }
}

.chat-app .overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}

.chat-app .overlay.open {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .chat-app .people-list {
    height: 100vh;
    overflow-y: auto;
  }

  .chat-app .chat-history {
    height: auto;
    overflow-y: auto;
  }

  .builtin-messages {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .chat-app .people-list {
    height: 480px;
    overflow-y: auto;
  }

  .chat-app .chat-history {
    height: 100vh;
    overflow-y: auto;
  }
}

.card-btn {
  transition: background-color 0.3s ease;
}

.card-btn:hover {
  background-color: rgba(177, 177, 177, 0.2);
}

.card-img {
  max-height: 85px;
  width: auto;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) and (min-width: 480px) {
  .card-img {
    max-height: 70px;
    width: auto;
  }
}

@media only screen and (max-width: 481px) and (min-width: 425px) {
  .card-img {
    max-height: 60px;
    width: auto;
  }
}

@media only screen and (max-width: 424px) {
  .card-img {
    max-height: 80px;
    width: auto;
  }

  body {
    background-color: #f4f7f6;
    margin-top: 0px;
    height: 90vh;
  }

  .card-footer-text {
    font-size: 11px;
  }
}

@media only screen and (max-width: 374px) {
  .card-img {
    max-height: 75px;
    width: auto;
  }

  body {
    background-color: #f4f7f6;
    margin-top: 0px;
    height: 90vh;
  }
}

.form-check-input {
  display: none;
}

.form-check-label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.form-check-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 2px solid black;
  border-radius: 50%;
  background-color: white;
}

.form-check-input:checked+.form-check-label::before {
  background-color: black;
  border-color: black;
}

.form-check-label::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  transition: background-color 0.2s;
}

.form-control:focus {
  border: 1px solid #049853;
  box-shadow: none;
}

.form-check-input:checked+.form-check-label::after {
  background-color: white;
}

.float-right {
  float: right;
}

.img-bot {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 12px;
}

.justify-content-right {
  justify-content: right;
}

.container {
  width: auto;
  max-width: initial;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

.card-footer {
  font-size: 14px;
}

.items-center {
  display: flex;
  align-items: center;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e8f1f3;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #049853;
  border-radius: 3px;
}

.btn.btn-outline-secondary {
  background-color: #049853;
  color: white;
  border: 1.9px solid transparent;
}

.btn.btn-outline-secondary:hover {
  border: 1.9px solid #049853;
  background-color: transparent;
  color: #049853;
}